import React from "react";
import { withRouter } from '../../common/with-router';
import { Link } from 'react-router-dom';
import './style.css';
import ProductService from "../../services/product.service";
import CategoryService from "../../services/category.service";

function ViewProductAdmin(props){
    const [item, setItem] = React.useState([]);
    const [category,setCategory] = React.useState([]);
    React.useEffect(()=>{
        ProductService.get(props.router.params.id)
          .then((res) => {
            setItem(res.data);    
            CategoryService.get(res.data.categoryId)
            .then((response)=>{setCategory(response.data)})        
          })
        },
        [props.router.params.id]
    );

    return (
      <>
      <div className="container">
        <div className="product-details-style-1">
            <div className="row flex-lg-row align-items-center">
              
              <div className="col-lg-6">
                <div className="product-details-image mt-50">
                  <div className="product-image">
                    <div className="product-image-active tab-content" id="v-pills-tabContent">
                      <div className="single-image tab-pane fade show active" id="v-pills-one" role="tabpanel" aria-labelledby="v-pills-one-tab">
                        <img className="productImg" src={item['photo']} alt={item['photo']}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="product-details-content mt-45">
                  <p className="sub-title">
                    <Link to={"/list-product/" + category._id} >
                      {category.title}
                    </Link>
                  </p>
                  <h2 className="title">{item['title']}</h2>

                <div className="product-select-wrapper flex-wrap">
                  <div dangerouslySetInnerHTML={{ __html: item['desc']}} />

                  <div className="product-price">
                    <p className="sale-price">{item['price']} сом</p>
                  </div>

                  <div className="product-btn">
                    {/* <div className="text-center"> */}
                    <Link className="btn btn-outline-dark mt-auto" to={"#"} >
                        <i className="bi bi-whatsapp whatsapp-color"></i>&nbsp;
                        Связаться с продавцом
                      </Link>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>

          </div>        
        </div>
      </div>




      <div className="col-md-12">
        <Link to={'/update-product/'+props.router.params.id}>Изменить</Link>
      </div>
      </>
    );
}

export default withRouter(ViewProductAdmin);