import React, { useState, useEffect, useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import CategoryService from "./services/category.service";

function CollapsibleAdminMenu() {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    CategoryService.getAllActiveCategories()
    .then((res) => {
      console.log(res.data)
      setCategories(res.data);        

    })
    .catch(error => {
      console.error('There was an error!', error);
      setCategories([]); // Set data to an empty array in case of an error
    });
  }, []); // Empty dependency array to run only once after the component mounts

  const categoryMenuItems = useMemo(() => {
    return categories.map((category) => (
      <Nav.Link key={category._id} href={`/list-product-admin/${category._id}`}>{category.title}</Nav.Link>      
    ));
  }, [categories]);
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" bg="dark" data-bs-theme="dark">
      <Container>
        <Navbar.Brand href="/list-category">Категория</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {categoryMenuItems}
          </Nav>
        </Navbar.Collapse>

      </Container>
    </Navbar>
  );
}

export default CollapsibleAdminMenu;