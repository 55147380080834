import React from "react";
import { withRouter } from '../../common/with-router';
import { Link } from 'react-router-dom';

import UserService from "../../services/user.service";
import RoleService from "../../services/role.service";
// import BranchService from "../../services/branch.service";

function ViewUser(props){
    const [item, setItem] = React.useState([]);
    const [role, setRole] = React.useState([]);
    // const [branch, setBranch] = React.useState([]);
    React.useEffect(()=>{
        UserService.get(props.router.params.id)
          .then((res) => {
            // BranchService.get(res.data['branchId'])
            //   .then((responseBranch)=>setBranch(responseBranch.data))
            RoleService.get(res.data['roles'][0])
                .then((roleResponse)=>{
                  console.log('view user',res.data)
                  setRole(roleResponse.data);
                  console.log('view user role: ',roleResponse.data)
                  setItem(res.data);
                })
            
          })},[props.router.params.id]
    );

    return (
      <div className="col-md-12">
        <ul>
            <li>{item['_id']}</li>
            <li>Username:  {item['username']}</li>
            <li>Email:  {item['email']}</li>
            <li>Роль:  {role['name']}</li>
            {/* <li>Филиал:  {branch['title'] ? branch['title'] : 'жок'}</li> */}
        </ul>
        <Link to={'/update-user/'+props.router.params.id}>Өзгөртүү</Link>
      </div>
    );
}

export default withRouter(ViewUser);