import React from "react";
import { withRouter } from '../../common/with-router';
import { Link } from 'react-router-dom';
import './style.css';
import CategoryService from "../../services/category.service";

function ViewCategory(props){
    const [item, setItem] = React.useState([]);
    React.useEffect(()=>{
        CategoryService.get(props.router.params.id)
          .then((res) => {
            setItem(res.data);            
          })
        },
        [props.router.params.id]
    );

    return (
      <div className="col-md-12">
        <ul>
            {/* <li>{item['_id']}</li> */}
            <li>Названия:  {item['title']}</li>
            <li>Статус:  {item['status']? 'Открытый':'Закрытый'}</li>
        </ul>
        <Link to={'/update-category/'+props.router.params.id}>Изменить</Link>
      </div>
    );
}

export default withRouter(ViewCategory);