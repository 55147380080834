import React from "react";
import { withRouter } from '../../common/with-router';
import { Link } from 'react-router-dom';

import CategoryService from "../../services/category.service";
// import "../branchs/style.css";
import {Modal, Button} from 'react-bootstrap';

function ListCategory(props){
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [categoryId, setCategoryId] = React.useState(''); 
    
    function open(){
      handleShow();
    }
    const deletedCategory = (categoryId) => {
      CategoryService.delete(categoryId)
        .then((res)=>{
            console.log(categoryId)
            handleClose();        
            window.location.reload();   
        });   
    }
    const [items, setItems] = React.useState(null);
    React.useEffect(()=>{
        CategoryService.getAll()
          .then((res) => {
            console.log(res.data)
            setItems(res.data);            
          })
          .catch(error => {
            console.error('There was an error!', error);
            setItems([]); // Set data to an empty array in case of an error
          });
        },
        []
    );

    if (items === null) {
      return <div>Loading...</div>;
    }
    return (
      <div className="col-md-12">
              
              <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton >
                  <Modal.Title>Удалить категорию</Modal.Title>
                </Modal.Header>
                <Modal.Body>Хотите удалить эту категорию?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Отмена
                  </Button>
                  <Button variant="primary" onClick={()=>deletedCategory(categoryId)}>
                    Удалить
                  </Button>
                </Modal.Footer>
              </Modal>
        
        <h3 className="page-title">Категория</h3>
        <div className="button-wrapper">
          <Link className="btn btn-success" to={'/create-category'}>Создать</Link>
        </div>
        {items.length > 0 ?
        <table>
            <thead>
                <tr>
                    <th>№</th>
                    <th>Названия</th>
                    <th>Статус</th>
                </tr>
            </thead>

            <tbody>
                {items.map((item,index) => (
                    <tr key={item['_id']}>
                        <td>{index}</td>
                        <td>{item['title']}</td>
                        <td>{item['status']? 'Открытый':'Закрытый'}</td>
                        <td>
                          <Link to={'/update-category/'+item['_id']}>
                            <i className="bi bi-pencil-square"></i>
                          </Link>
                        </td>
                        <td>
                          <Link to={'/view-category/'+item['_id']}>
                            <i className="bi bi-eye"></i>
                          </Link>
                        </td>
                        <td>
                          <i onClick={()=>{open();setCategoryId(item['_id'])}} className="bi bi-trash remove-icon-color"></i>
                        </td>
                    </tr>
                ))} 
            </tbody>
        </table>
       : <div>Ничего не найдено</div>}
      </div>
    )
}

export default withRouter(ListCategory);