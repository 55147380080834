import React from "react";
import { withRouter } from '../../common/with-router';
import { Link } from 'react-router-dom';

import UserService from "../../services/user.service";
// import "../branchs/style.css";
import {Modal, Button} from 'react-bootstrap';

function ListUser(props){
    const [items, setItems] = React.useState([]);
    React.useEffect(()=>{
        UserService.getAll()
          .then((res) => {
            console.log(res.data)
            setItems(res.data);            
          })
        },
        []
    );
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [userId, setUserId] = React.useState(''); 
    
    function open(){
      handleShow();
    }
    const deletedUser = (userId) => {
      UserService.delete(userId)
        .then((res)=>{
            console.log(userId)
            handleClose();        
        });
      window.location.reload();      
    }
    return (
      <div className="col-md-12">
              
              <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton >
                  <Modal.Title>Колдонуучуну өчүрүү</Modal.Title>
                </Modal.Header>
                <Modal.Body>Тандалган колдонуучу өчүрүлсүнбү?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Жокко чыгаруу
                  </Button>
                  <Button variant="primary" onClick={()=>deletedUser(userId)}>
                    Өчүрүү
                  </Button>
                </Modal.Footer>
              </Modal>
        
        <h3 className="page-title">Колдонуучулар</h3>
        <div className="button-wrapper">
          <Link className="btn btn-success" to={'/create-user'}>Жаңы колдонуучу киргизүү</Link>
        </div>
        <table>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Роль</th>
                    <th>Филиал</th>
                </tr>
            </thead>

            <tbody>
                {items.map((item,index) => (
                    <tr key={item['_id']}>
                        <td>{index}</td>
                        <td>{item['username']}</td>
                        <td>{item['email']}</td>
                        <td>{item['roles'][0].title}</td>
                        {/* <td>{item['branch'][0].title ? item['branch'][0].title : 'жок'}</td> */}
                        <td>
                          <Link to={'/update-user/'+item['_id']}>
                            <i className="bi bi-pencil-square"></i>
                          </Link>
                        </td>
                        {/* <td>
                          <Link to={'/view-user/'+item['_id']}>
                            <i className="bi bi-eye"></i>
                          </Link>
                        </td> */}
                        <td>
                          <i onClick={()=>{open();setUserId(item['_id'])}} className="bi bi-trash remove-icon-color"></i>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
      </div>
    )
}

export default withRouter(ListUser);