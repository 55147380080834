import axios from 'axios';

const API_URL = 'https://api.liftmarket.kg/api/role/';
// const API_URL = 'http://localhost:4004/api/role/';

class RoleService {

  get(id) {
    return axios.get(API_URL + 'get',{'params':{'id':id}});
  }

  getAll() {
    return axios.get(API_URL + 'getAll');
  }
}

export default RoleService = new RoleService();