import React from "react";
import { withRouter } from '../../common/with-router';
import { Link } from 'react-router-dom';
import CategoryService from "../../services/category.service";

function ListProduct(props){

    const [items, setItems] = React.useState(null);
    const [category,setCategory] = React.useState(null);
    React.useEffect(()=>{
        CategoryService.get(props.router.params.categoryId)
          .then((res)=>{setCategory(res.data)})
          .catch((error)=>{console.error('There was an error!', error);})
        CategoryService.getAllProductsByCategoryId(props.router.params.categoryId)
          .then((res) => {
            console.log(res.data)
            setItems(res.data);            
          })
          .catch(error => {
            console.error('There was an error!', error);
            setItems([]); // Set data to an empty array in case of an error
          });
        },[props.router.params.categoryId]
    );

    if (items === null) {
      return <div>Loading...</div>;
    }
    return (
      <div className="col-md-12">
              
        
        <h3 className="page-title">{category.title}</h3>


        <section>

            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 row-cols-2-mine row-cols-md-3 row-cols-xl-4 justify-content-center">
                {items.length > 0 ? items.map((item,index) => (
                    <div key={item['_id']} className="col mb-5">
                      <Link to={'/view-product/'+item['_id']}>
                          <div className="card">
                              <img className="card-img-top" src={item['photo']} alt={item['photo']}/>
                              <div className="card-body p-4">
                                  <div className="text-center">
                                      <h5 className="fw-bolder">{item['title']}</h5>
                                      <div className="d-flex justify-content-center small text-warning mb-2">
                                          <div className="bi-star-fill"></div>
                                          <div className="bi-star-fill"></div>
                                          <div className="bi-star-fill"></div>
                                          <div className="bi-star-fill"></div>
                                          <div className="bi-star-fill"></div>
                                      </div>
                                      {item['price']} сом
                                  </div>
                              </div>
                              <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                  <div className="text-center">
                                    <span className="btn btn-outline-dark mt-auto">Подробнее</span>
                                  </div>
                              </div>
                          </div>
                        </Link>
                    </div>
                  )) : <div>Ничего не найдено</div>}
                </div>
            </div>
        
        </section>
      </div>
    )
}

export default withRouter(ListProduct);