import React from "react";
import { withRouter } from '../../common/with-router';

import ProductService from "../../services/product.service";
// import './style.css';

function CreateMultiple(props){

    const [title, setTitle] = React.useState('');
    const [desc, setDesc] = React.useState('');
    const [photo, setPhoto] = React.useState([]);
    const [price, setPrice] = React.useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('photo: ',photo)
        if(title === ''){
          alert('Филиалды жазыңыз!')
        }else{
          // const inputFile = document.getElementById("fileInput");
          // let formData = new FormData();
          // formData.append('title',title)
          // formData.append('desc',desc)
          // formData.append('photo',inputFile?.files[0])
          // formData.append('photo',photo)
          // formData.append('price',price)
          const a = [1,2,3];
          console.log('a: ',a)
          console.log('typeof a: ',typeof a)
          let i=0;
            const l=photo.length-1;
            for(i;i<=l;i++){
              console.log(`photo[${i}]`,i,photo[i])
            }
          console.log('typeof photo: ',typeof photo)
          // if(typeof photo === FileList){

            // if(Array.isArray(photo)){
            // let i=0;
            // const l=photo.length-1;
            // for(i;i<=l;i++){
            // photo.forEach(photo => {
              console.log('before setTimeout: ',photo)
              // setTimeout(()=>{

                ProductService.create(
                  title,desc,photo,price
                ).then(
                  (response) => {
                    props.router.navigate("/view-product/"+response.data._id);
                  },
                );

              // },1000)
            // });
              
            // }
          // }
        }
    }
    // const uploadFile = e => {setPhoto(e.target.files[0]);console.log('files: ',e.target.files)}
    const uploadFile = e => {
      // setPhoto(e.target.files);
      console.log('files: ',e.target.files);
      console.log('slice files: ',Array.prototype.slice.call(e.target.files))
      setPhoto(Array.prototype.slice.call(e.target.files))
    }
    return (
      <div className="col-md-12">
        <div className="card card-container">
          <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">
            <div>

                <div className="form-group">
                  <label htmlFor="title">Аты</label>
                    <input className="form-control"
                      type="text"
                      name="title"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                </div>

                <div className="form-group">
                  <label htmlFor="desc">Түшүндүрмө</label>
                    <input className="form-control"
                      type="text"
                      name="desc"
                      value={desc}
                      onChange={e => setDesc(e.target.value)}
                    />
                </div>

                <div className="form-group">
                  <label htmlFor="photo">Сүрөтү</label>
                    <input className="form-control"
                      accept="image/*"
                      id="inputFile"
                      type="file"
                      name="photo"
                      value={""}
                      onChange={(e)=>uploadFile(e)}
                      multiple
                    />
                </div>

                <div className="form-group">
                  <label htmlFor="price">Баасы</label>
                    <input className="form-control"
                      type="text"
                      name="price"
                      value={price}
                      onChange={e => setPrice(e.target.value)}
                    />
                </div>

                {/* <div className="form-group">  
                  <p>Колдонулсун</p>                  
                    <div>
                      <input type="radio"  name="registrationApp" value="true" checked={registrationApp === 'true'} onChange={e => setRegistrationApp(e.target.value)}></input>
                      <label className="registrationApp" htmlFor="registrationApp">Ооба</label>   
                    </div>               
                    <div>
                      <input type="radio" name="registrationApp" value="false" checked={registrationApp  === 'false'} onChange={e => setRegistrationApp(e.target.value)}></input>
                      <label className="registrationApp" htmlFor="registrationApp">Жок</label>
                    </div>
                </div> */}
   
            </div>

            <button type="submit" >Сакта</button>
          </form>
        </div>
      </div>
    );
}

export default withRouter(CreateMultiple);