import React from "react";
import { withRouter } from '../../common/with-router';

import CategoryService from "../../services/category.service";
import "./style.css";
function ViewCategory(props){
  
    const [title, setTitle] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [seoFriendlyUrl, setSeoFriendlyUrl] = React.useState('');
    // const cyrillicToLatinMap = {
    //   'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'E', 'Ж': 'Zh',
    //   'З': 'Z', 'И': 'I', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N', 'О': 'O',
    //   'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F', 'Х': 'Kh', 'Ц': 'Ts',
    //   'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Shch', 'Ъ': '', 'Ы': 'Y', 'Ь': '', 'Э': 'E', 'Ю': 'Yu',
    //   'Я': 'Ya', 'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'e',
    //   'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
    //   'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'kh',
    //   'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'shch', 'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e',
    //   'ю': 'yu', 'я': 'ya'
    // };
    // function convertToSeoFriendlyUrl(text) {
    //   // Convert each Cyrillic character to Latin
    //   const latinText = text.split('').map(char => cyrillicToLatinMap[char] || char).join('');    
    //   // Make the URL SEO friendly
    //   return latinText
    //     .replace(/[\s_]+/g, '-') // Replace spaces and underscores with hyphens
    //     .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    //     .replace(/\-\-+/g, '-')   // Replace multiple hyphens with a single hyphen
    //     .replace(/^-+|-+$/g, '')  // Trim hyphens from the start and end of the text
    //     .toLowerCase();           // Convert to lowercase
    // }
    React.useEffect(()=>{
      
        CategoryService.get(props.router.params.id)
          .then((res) => {
            console.log('update category: ',res.data)
            setTitle(res.data.title);
            setStatus(res.data.status);
            setSeoFriendlyUrl(res.data.seoFriendlyUrl)
          });                    
        },[props.router.params.id]
    );

    const handleSubmit = (e) => {        
        e.preventDefault();
        // setSeoFriendlyUrl(title);
        if(title === ''){
            alert('Введите названия!')
        }else{

            CategoryService.update(
            props.router.params.id, title, seoFriendlyUrl, status       
            ).then(
            (response) => {
                console.log(response)
                props.router.navigate("/view-category/"+props.router.params.id);
            }
            );
        }

    }
    return (
      <div className="col-md-12">
        <div className="card card-container">
          <form onSubmit={handleSubmit} method="post" >
            <div className="form">

                <div className="form-group">
                  <label htmlFor="title">Названия</label>
                    <input className="form-control"
                      type="text"
                      name="title"
                      value={title}
                      onChange={e => {setTitle(e.target.value);setSeoFriendlyUrl(e.target.value)}}
                    />
                </div>

                <div className="form-group">  
                  <p>Статус</p>                  
                    <div>
                      <input type="radio"  name="status" value="true" checked={ status === true} onChange={e => setStatus(true)}></input>
                      <label className="status" htmlFor="status">Открытый</label>   
                    </div>               
                    <div>
                      <input type="radio" name="status" value="false" checked={ status  === false} onChange={e => setStatus(false)}></input>
                      <label className="status" htmlFor="status">Закрытый</label>
                    </div>
                </div>
   
            </div>

            <button type="submit" >Обновить</button>
          </form>
        </div>
      </div>
    );
}

export default withRouter(ViewCategory);