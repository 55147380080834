import React from "react";
import { withRouter } from '../../common/with-router';

import UserService from "../../services/user.service";
import RoleService from "../../services/role.service";
// import BranchService from "../../services/branch.service";
// import '../branchs/style.css';

function CreateUser(props){
    const [allRoles, setAllRoles] = React.useState([]);
    const [username, setUsername] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [roles, setRoles] = React.useState('');
    // const [branchOptions, setBranchOptions] = React.useState([])
    // const [branchId, setBranchId] = React.useState(branchOptions[0]);
    // const [groupToggleMine, setGroupToggleMine] = React.useState(false);
    
    React.useEffect(()=>{
        RoleService.getAll()
          .then((res) => {
            console.log(res.data)
            setAllRoles(res.data)            
          });
        // BranchService.getAllRegistrationAppBranches()
        //   .then((res) => {
        //     setBranchOptions(res.data);
        //   })
        },
        []
    );
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if(username === ''){
          alert('Username жазыңыз!')
        }else if(email === ''){
          alert('Email жазыңыз!')
        }else if(password === ''){
          alert('Пароль жазыңыз!')
        }else{

          UserService.create(
            username,
            email,
            password,
            [roles],
            // branchId
          ).then(
            (response) => {
              console.log('response user: ',response.data)
              if(response.data._id)
                props.router.navigate("/view-user/"+response.data._id);
            },
          );
        }
    }
    
    return (
      <div className="col-md-12">
        <div className="card card-container">
          <form onSubmit={handleSubmit} method="post">
            <div>

                <div className="form-group">
                  <label htmlFor="username">Username</label>
                    <input className="form-control"
                      type="text"
                      name="username"
                      value={username}
                      onChange={e => setUsername(e.target.value)}
                    />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                    <input className="form-control"
                      type="text"
                      name="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Пароль</label>
                    <input className="form-control"
                      type="text"
                      name="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                </div>

                <div className="form-group">  
                  <p>Роль</p>           
                    {allRoles.map(result => (
                      
                      <div key={result._id}>
                        <input type="radio"  name="roles" value={result.name} checked={roles === result.name} 
                          onChange={e => {
                            setRoles(e.target.value);
                            }
                        }></input>
                        <label className="role" htmlFor="roles">{result.name}</label> 
                      </div>
                      ))
                    }
                </div>
   
            </div>

            <button type="submit" >Сакта</button>
          </form>
        </div>
      </div>
    );
}

export default withRouter(CreateUser);