import React from "react";
import { withRouter } from '../../common/with-router';

import ProductService from "../../services/product.service";
import './style.css';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';

function CreateProduct(props){

    const [title, setTitle] = React.useState('');
    const [desc, setDesc] = React.useState('');
    const [photo, setPhoto] = React.useState(undefined);
    const [seoFriendlyUrl, setSeoFriendlyUrl] = React.useState('');
    const [price, setPrice] = React.useState('');
    const [imageUrl, setImageUrl] = React.useState(undefined);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('photo: ',photo)
        if(title === ''){
          alert('Напишите названия!')
        }else{
          console.log('typeof photo: ',typeof photo)
          ProductService.create(
            title, props.router.params.categoryId, desc, seoFriendlyUrl, photo,price
          ).then(
            (response) => {
              props.router.navigate("/view-product-admin/"+response.data._id);
            },
          );
        }
    }
    const uploadFile = e => {
      const file = e.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {      setImageUrl(reader.result); 
        };
        reader.readAsDataURL(file);
      }
      setPhoto(e.target.files[0]); 
    }
    const onEditorStateChange = (newEditorState) => {
      setEditorState(newEditorState);
      const rawContentState = convertToRaw(newEditorState.getCurrentContent());
      const markup = draftToHtml(rawContentState);
      setDesc(markup);
    };
    return (
      <div className="col-md-12">
        <div className="card">
          <h3>Создать продукт</h3>
          <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">
            <div>

                <div className="form-group">
                  <label htmlFor="title">Названия</label>
                    <input className="form-control"
                      type="text"
                      name="title"
                      value={title}
                      onChange={e => {setTitle(e.target.value);setSeoFriendlyUrl(e.target.value)}}
                    />
                </div>

                <div className="form-group">
                  <label htmlFor="desc">Описания</label>
                  {/* <div className="descHtml" dangerouslySetInnerHTML={{ __html: desc }} /> */}
                  <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="photo">Рисунок</label>
                    {imageUrl && <img className="photo" alt={imageUrl} src={imageUrl} />}
                    <input className="form-control"
                      accept="image/*"
                      id="inputFile"
                      type="file"
                      name="photo"
                      value={""}
                      onChange={(e)=>uploadFile(e)}
                      multiple
                    />
                </div>

                <div className="form-group">
                  <label htmlFor="price">Цена</label>
                    <input className="form-control"
                      type="text"
                      name="price"
                      value={price}
                      onChange={e => setPrice(e.target.value)}
                    />
                </div>
   
            </div>

            <button type="submit" >Сохранить</button>
          </form>
        </div>
      </div>
    );
}

export default withRouter(CreateProduct);