import React from "react";
import { withRouter } from '../../common/with-router';

import ProductService from "../../services/product.service";
import "./style.css";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
function ViewProduct(props){
  
    const [title, setTitle] = React.useState('');
    const [desc, setDesc] = React.useState('');
    const [seoFriendlyUrl, setSeoFriendlyUrl] = React.useState('');
    const [photo, setPhoto] = React.useState('');
    const [price, setPrice] = React.useState('');
    const [imageUrl, setImageUrl] = React.useState(undefined);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [isUpdWithPhoto,setIsUpdWithPhoto] = React.useState(true);
    React.useEffect(()=>{
      
        ProductService.get(props.router.params.id)
          .then((res) => {
            console.log('update product: ',res.data)
            setTitle(res.data.title);
            setPrice(res.data.price) 
            setImageUrl(res.data.photo);
            if (res.data.desc) {
              setDesc(res.data.desc)
              const blocksFromHtml = htmlToDraft(res.data.desc);
              const { contentBlocks, entityMap } = blocksFromHtml;
              const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
              const editorState = EditorState.createWithContent(contentState);
              setEditorState(editorState);
            }
            console.log('photo from db: ',res.data.photo);
          });                    
        },[props.router.params.id]
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        if(title === ''){
            alert('Напишите названия!')
        }else{

          if(isUpdWithPhoto){
            ProductService.updateWithoutPhoto(
              props.router.params.id, title, desc, seoFriendlyUrl, price            
            ).then(
              (response) => {
                console.log(response)
                props.router.navigate("/view-product-admin/"+props.router.params.id);
              }
            );
            }else{
              ProductService.update(
                props.router.params.id, title, desc, seoFriendlyUrl, photo, price            
              ).then(
                (response) => {
                  console.log(response)
                  props.router.navigate("/view-product-admin/"+props.router.params.id);
                }
              );
            }
        }

    }
    const uploadFile = e => {
      const file = e.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {      setImageUrl(reader.result); 
        };
        reader.readAsDataURL(file);
      }
      setPhoto(e.target.files[0]); 
      setIsUpdWithPhoto(false);
    }    
    const onEditorStateChange = (newEditorState) => {
      setEditorState(newEditorState);
      const rawContentState = convertToRaw(newEditorState.getCurrentContent());
      const markup = draftToHtml(rawContentState);
      setDesc(markup)
    }
    return (
      <div className="col-md-12">
        <div className="card">
          <h3>Изменить продукт</h3>
          <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">
            <div>

                <div className="form-group">
                  <label htmlFor="title">Названия</label>
                    <input className="form-control"
                      type="text"
                      name="title"
                      value={title}
                      onChange={e => {setTitle(e.target.value);setSeoFriendlyUrl(e.target.value)}}
                    />
                </div>

                <div className="form-group">
                  <label htmlFor="desc">Описания</label>
                  {/* <div className="descHtml" dangerouslySetInnerHTML={{ __html: desc }} /> */}
                  <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange}
                  />   
                </div>

                <div className="form-group">
                  <label htmlFor="photo">Рисунок</label>
                    {imageUrl && <img className="photo" alt={imageUrl} src={imageUrl} />}
                    <input className="form-control"
                      accept="image/*"
                      id="inputFile"
                      type="file"
                      name="photo"
                      value={""}
                      onChange={(e)=>uploadFile(e)}
                      multiple
                    />
                </div>

                <div className="form-group">
                  <label htmlFor="price">Цена</label>
                    <input className="form-control"
                      type="text"
                      name="price"
                      value={price}
                      onChange={e => setPrice(e.target.value)}
                    />
                </div>
   
            </div>

            <button type="submit" >Изменить</button>
          </form>
        </div>
      </div>
    );
}

export default withRouter(ViewProduct);