// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descHtml{padding: 10px;background-color: LightGray;}
.photo{width: 120px; height: 80px;}
p{margin-bottom: 0.3rem;}`, "",{"version":3,"sources":["webpack://./src/components/products/style.css"],"names":[],"mappings":"AAAA,UAAU,aAAa,CAAC,2BAA2B,CAAC;AACpD,OAAO,YAAY,EAAE,YAAY,CAAC;AAClC,EAAE,qBAAqB,CAAC","sourcesContent":[".descHtml{padding: 10px;background-color: LightGray;}\n.photo{width: 120px; height: 80px;}\np{margin-bottom: 0.3rem;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
