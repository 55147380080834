import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://api.liftmarket.kg/api/product/';

class ProductService {

    get(id) {
        return axios.get(API_URL + 'get',{'params':{'id':id}});
    }

    getAll() {
        return axios.get(API_URL + 'getAll', { headers: authHeader() });
    }
    
    create(title, categoryId, desc, seoFriendlyUrl, photo, price) {
        return axios.post(API_URL + "create", 
        {  title, categoryId, desc, seoFriendlyUrl, photo, price },
        {headers: { "Content-Type": "multipart/form-data" }});
    }

    update(_id, title, desc, seoFriendlyUrl, photo, price) {
        console.log('price: ',price)
        return axios.put(API_URL + "update", 
        {  _id, title, desc, seoFriendlyUrl, photo, price },
        {headers: { "Content-Type": "multipart/form-data" }});
    }

    updateWithoutPhoto(_id, title, desc, seoFriendlyUrl, price) {
        console.log('price: ',price)
        return axios.put(API_URL + "updateWithoutPhoto", 
        {              _id, title, desc, seoFriendlyUrl, price });
    }

    delete(id) {
        console.log(id)
        return axios.delete(API_URL + 'delete?id=' + id);
    }
}

export default ProductService = new ProductService();