import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://api.liftmarket.kg/api/xlsx/';

class XlsxService {

    get(id) {
        return axios.get(API_URL + 'get',{'params':{'id':id}});
    }

    getAll() {
        return axios.get(API_URL + 'getAll', { headers: authHeader() });
    }
    
    // create(formData) {
    //     console.log('formData: ',formData)
    //     return axios.post(API_URL + "create", {
    //     formData
    //     },{headers: { "Content-Type": "multipart/form-data" }});
    // }
    create(title, desc, photo, price) {
        console.log('price: ',photo)
        return axios.post(API_URL + "create", {
        title,
        desc,
        photo,
        price
        },{headers: { "Content-Type": "multipart/form-data" }});
    }

    update(_id, title, desc, photo, price) {
        console.log('price: ',price)
        return axios.put(API_URL + "update", {
        _id,
        title,
        desc,
        photo,
        price
        });
    }
}

export default XlsxService = new XlsxService();