import React, { Component } from "react";

import { Link } from 'react-router-dom';
import ProductService from "../../services/product.service";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: []
    };
  }

  componentDidMount() {
    ProductService.getAll()
    .then((response)=>{
      this.setState({products: response.data})
    },(error)=>{
      this.setState({
        products:
          (error.response && error.response.data) ||
          error.message ||
          error.toString()
      });
    });
  }

  render() {
    return (
      <div className="col-md-12">
          <section >

              <div className="container px-4 px-lg-5 ">
                <div className="row gx-4 gx-lg-5 row-cols-2-mine row-cols-md-3 row-cols-xl-4 justify-content-center">
                {this.state.products.length > 0 ? this.state.products.map((item,index) => (
                    <div key={item['_id']} className="col mb-5">
                      <Link to={'/view-product/'+item['_id']}>
                          <div className="card">
                              <img className="card-img-top" src={item['photo']} alt={item['photo']}/>
                              <div className="card-body p-4">
                                  <div className="text-center">
                                      <h5 className="fw-bolder">{item['title']}</h5>
                                      <div className="d-flex justify-content-center small text-warning mb-2">
                                          <div className="bi-star-fill"></div>
                                          <div className="bi-star-fill"></div>
                                          <div className="bi-star-fill"></div>
                                          <div className="bi-star-fill"></div>
                                          <div className="bi-star-fill"></div>
                                      </div>
                                      {item['price']} сом
                                  </div>
                              </div>
                              <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                  <div className="text-center">
                                    <span className="btn btn-outline-dark mt-auto">Подробнее</span>
                                  </div>
                              </div>
                          </div>
                        </Link>
                    </div>
                  )) : <div>Ничего не найдено</div>}
                </div>
              </div>

          </section>
      </div>
    );
  }
}