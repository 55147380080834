import React from "react";
import { withRouter } from '../../common/with-router';
import { Link } from 'react-router-dom';

import ProductService from "../../services/product.service";
// import "../branchs/style.css";
import {Modal, Button, Card} from 'react-bootstrap';
import CategoryService from "../../services/category.service";

function ListProductAdmin(props){
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [productId, setProductId] = React.useState(''); 
    const [category,setCategory] = React.useState(null);
    
    function open(){
      handleShow();
    }
    const deletedProduct = (productId) => {
      ProductService.delete(productId)
        .then((res)=>{
            console.log(productId)
            handleClose();        
            window.location.reload();
        });
            
    }
    const [items, setItems] = React.useState(null);
    React.useEffect(()=>{
      CategoryService.get(props.router.params.categoryId)
      .then((res)=>{setCategory(res.data)})
      .catch((error)=>{console.error('There was an error!', error);})
        CategoryService.getAllProductsByCategoryId(props.router.params.categoryId)
          .then((res) => {
            console.log(res.data)
            setItems(res.data);            
          })
          .catch(error => {
            console.error('There was an error!', error);
            setItems([]); // Set data to an empty array in case of an error
          });
        },[props.router.params.categoryId]
    );

    if (items === null) {
      return <div>Loading...</div>;
    }
    return (
      <div className="col-md-12">
              
              <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton >
                  <Modal.Title>Удалить продукт</Modal.Title>
                </Modal.Header>
                <Modal.Body>Хотите удалить выбранный продукт?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Отмена
                  </Button>
                  <Button variant="primary" onClick={()=>deletedProduct(productId)}>
                    Удалить
                  </Button>
                </Modal.Footer>
              </Modal>
        
        <h3 className="page-title">{category ? category.title : ''}</h3>
        <div className="button-wrapper">
          <Link className="btn btn-success" to={'/create-product/'+ props.router.params.categoryId}>Введите новый продукт</Link>
        </div>
        {items.length > 0 ?
        <table>
            <thead>
                <tr>
                    <th>№</th>
                    <th>Названия</th>
                    <th>Описания</th>
                    <th>Рисунок</th>
                    <th>Цена</th>
                </tr>
            </thead>

            <tbody>
                { items.map((item,index) => (
                    <tr key={item['_id']}>
                        <td>{index + 1}</td>
                        <td>{item['title']}</td>
                        <td><div dangerouslySetInnerHTML={{ __html: item['desc'] }} /></td>
                        <td><img  className="photo" src={item['photo']} alt={item['photo']} ></img></td>
                        <td>{item['price']}</td>
                        <td>
                          <Link to={'/update-product/'+item['_id']}>
                            <i className="bi bi-pencil-square"></i>
                          </Link>
                        </td>
                        <td>
                          <Link to={'/view-product-admin/'+item['_id']}>
                            <i className="bi bi-eye"></i>
                          </Link>
                        </td>
                        <td>
                          <i onClick={()=>{open();setProductId(item['_id'])}} className="bi bi-trash remove-icon-color"></i>
                        </td>
                    </tr>
                )) }
            </tbody>
        </table>
        : <div>Ничего не найдено</div>}




      </div>
    )
}

export default withRouter(ListProductAdmin);