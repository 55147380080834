import React from "react";
import { withRouter } from '../../common/with-router';
import { Link } from 'react-router-dom';

import XlsxService from "../../services/xlsx.service";
// import "../branchs/style.css";
import {Modal, Button} from 'react-bootstrap';

function ListXlsx(props){
    const [items, setItems] = React.useState([]);
    React.useEffect(()=>{
        XlsxService.getAll()
          .then((res) => {
            console.log(res.data)
            setItems(res.data);            
          })
        },
        []
    );
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [xlsxId, setXlsxId] = React.useState(''); 
    
    function open(){
      handleShow();
    }
    const deletedXlsx = (xlsxId) => {
      XlsxService.delete(xlsxId)
        .then((res)=>{
            console.log(xlsxId)
            handleClose();        
        });
      window.location.reload();      
    }
    return (
      <div className="col-md-12">
              
              <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton >
                  <Modal.Title>Продуктыны өчүрүү</Modal.Title>
                </Modal.Header>
                <Modal.Body>Тандалган продукт өчүрүлсүнбү?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Жокко чыгаруу
                  </Button>
                  <Button variant="primary" onClick={()=>deletedXlsx(xlsxId)}>
                    Өчүрүү
                  </Button>
                </Modal.Footer>
              </Modal>
        
        <h3 className="page-title">Продукты</h3>
        <div className="button-wrapper">
          <Link className="btn btn-success" to={'/create-xlsx'}>Жаңы продукт киргизүү</Link>
        </div>
        <table>
            <thead>
                <tr>
                    <th>№</th>
                    <th>Аты</th>
                    <th>Түшүндүрмө</th>
                    <th>Фото</th>
                    <th>Баасы</th>
                </tr>
            </thead>

            <tbody>
                {/* {items.map((item,index) => (
                    <tr key={item['_id']}>
                        <td>{index}</td>
                        <td>{item['title']}</td>
                        <td>{item['desc']}</td>
                        <td>{item['photo']}</td>
                        <td>{item['price']}</td>
                        <td>
                          <Link to={'/update-xlsx/'+item['_id']}>
                            <i className="bi bi-pencil-square"></i>
                          </Link>
                        </td>
                        <td>
                          <i onClick={()=>{open();setXlsxId(item['_id'])}} className="bi bi-trash remove-icon-color"></i>
                        </td>
                    </tr>
                ))} */}
            </tbody>
        </table>
      </div>
    )
}

export default withRouter(ListXlsx);