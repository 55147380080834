import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'ky',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      ky: {
        translation: {
         language: "Тил",
         intensiveType:"",
         intensiveDesc:"",
         lessons: "Предметтер",
         mainLessons:"Негизги",
         additionalLessons:"Кошумча",
         groups:"Группалар",
         mainGroups:"Негизги",
         additionalGroups:"Кошумча",
         fio:"Аты жөнү",
         school:"Мектеби",
         phone:"Телефону",
         total: "Жалпы сумма: ",
         reserve: "Брондоо"
        }
      },
      ru: {
        translation: {
         language: "Язык",
         intensiveType:"",
         intensiveDesc:"",
         lessons: "Предметы",
         mainLessons:"Основные",
         additionalLessons:"Дополнительные",
         groups:"Группы",
         mainGroups:"Основные",
         additionalGroups:"Дополнительные",
         fio:"ФИО",
         school:"Школа",
         phone:"Телефон",
         total: "Общая сумма: ",
         reserve: "Бронировать"
        }
      },
    }
  });

export default i18n;
